$clean-hero-slider-accent: $primary-color;

.clean-hero-slider {
    .orbit-caption {
        width: auto;
        max-width: 80%;
        height: auto;
        background: $black;
        position: relative;
        text-align: center;
        right: 0;
        left: 0;
        padding: 0.2rem 2rem;


        @include breakpoint(medium) {
            //@include vertical-center;
            background: rgba(0,0,0,.5);
            text-align: left;
            right: 0;
            padding: 1rem 2rem;

        }

        @include breakpoint(xlarge) {
            padding-right: 10%;
        }

        @include breakpoint(xxlarge) {
            padding-right: 20%;
        }

        p {
            line-height: 1.25;
            @include breakpoint(medium) {
                font-size: 1rem;
            }
        }


        h3 {
            color: $white;
            font-weight: bold;
            margin-bottom: 0;


        }
    }

    .orbit-slide {
        padding-bottom: 40%;
        background: $black;

        @include breakpoint(medium) {
            padding-bottom: 0;
        }
    }

    .orbit-controls {
        button {
            background: $clean-hero-slider-accent;
            padding: 1rem 0.5rem;

            &:hover {
                background: $white;
                color: $black;
            }

        }

        .orbit-previous {
            @include breakpoint(medium) {

                left: 0.5rem;
                //right: 2.4rem;
            }
        }

        .orbit-next {
            @include breakpoint(medium) {
                right: 0.5rem;
            }
        }
    }

    .orbit-bullets {
        display: none;

        @include breakpoint(medium) {
            display: block;
            margin-bottom: 0;
            margin-top: -1.5rem;
            background: none;
        }

        button {
            width: 3rem;
            height: 0.4rem;
            border-radius: 0;
            background-color: $light-gray;

            &.is-active {
                background-color: $clean-hero-slider-accent;
            }
        }
    }

    ul.orbit-container {
        height: auto !important;
        //@include breakpoint(xlarge) {
        //    min-height: 850px;
        //}
        //@include breakpoint(large) {
        //    min-height: 775px;
        //}
        //@include breakpoint(medium) {
        //min-height: 350px;
        //}


    }
}

li.orbit-slide {
    max-height: none !important;
}

ul.small-container{
    height:auto;
}

