@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
//@import 'node_modules/swiper/swiper';
//@import 'node_modules/swiper/modules/navigation';
//@import 'node_modules/swiper/modules/pagination';


//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500);

//$slick-font-path: 'assets/webfonts/';
//$slick-loader-path: 'node_modules/slick-carousel/slick/' ;


@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import "node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/@fortawesome/fontawesome-free/scss/v4-shims";
//@import 'fontawesome-free';

// Global styles
@include foundation-global-styles;
//@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
// @include foundation-xy-grid-classes;
// @include foundation-grid;
@include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-switch;
// @include foundation-table;
// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
// @include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

@import 'components/building-blocks/clean-hero-slider';
@import 'components/building-blocks/marketing-site-features';
@import 'components/building-blocks/social-icon-button';
//@import 'components/building-blocks/ecommerce-hero-slider-small';


p ~ p {
    margin-top: 3px;
}

.menu li{
    a {color:$black;
       font-weight:400;}
}

.footer {
    background-color: #414954;
    color: $white;
    padding: 10px;

    h1,h2,h3,h4,h5{
        color:$primary-color;
        font-weight: 300;
        font-variant: small-caps;
    }

    p {
        color: $white;
    }

    a { color: $white;
        &:hover {
            color: $secondary-color;
            transition: color 0.3s ease-in;
        }

    }
}


.header {
    background-color: $primary-color;
    padding: 1rem;
    color: $white;

    h1,h2,h3,h4,h5,hl{
        color:$white;
        font-weight:300;
    }

    p {
        color: $white;
    }

    slogan {
        font-weight:500;
        font-size:  6rem;
        margin-bottom: 0.5rem;

        p {

            @include breakpoint(large) {
                font-size: 6rem;
                font-weight: 500;
            }
            @include breakpoint(xlarge) {
                font-size: 6rem;
                font-weight: 500;
            }
        }
    }

    menu li.is-active > a {
        background: $white;
        color: $black;
    }

    menu,li {
        a {color:$white;
           &:hover {
               background-color: $white;
               color: $black;
               text-decoration: underline;
           }	
        }
    }

    .dropdown {
        .is-submenu-item > a {
            width: 30px;
            color: $black ;

        }
    }


    .dropdown.menu {

        // Top-level item
        > li > a {
            background: $dropdownmenu-background;
            padding: $dropdownmenu-padding;
        }

        // Top-level item active state
        > li.is-active > a {
            background: $white;
            color: $dropdown-menu-item-color-active;
        }
    }




}
.white{
    color:$white;
}

.yellow{
    color:yellow;
}

.xlwhitepad {
    margin:20px;
}

.social {

    width :100%;
    padding : 10px;
    align-items : left;

}

.xsmall {
    font-size: 10px;
}

.hollow.button {
    &.hover {
        background-color: #5ED5F0;
    }
}

.top-bar-left ul {
    a {color:$dark-blue;
       font-weight:500;
       &:hover {
           background-color: $primary-color;
           color: $white;
           text-decoration: underline;
       }
    }
}
.top-bar-right ul {
    a {color:$dark-blue;
       font-weight:500;
       &:hover {
           background-color: $primary-color;
           color: $white;
           text-decoration: underline;
       }
    }
}

.pink {
    color: $primary-color;
}

.social-footer-icons {
    li:last-of-type {
        margin-right: 0;
        align-items: right;
    }

    .fa {
        font-size: 1.3 rem;
        color: $white;

        &:hover {
            color: darken($primary-color, 5%);
            transition: color 0.3s ease-in;
        }
    }
}

#card-divider-bottom {
    background:$light-gray;
}

#intro {
    margin-top:15px;
    margin-bottom:15px;
}



.callout-alert{
    display: flex;
    align-items: flex-start;
    background: transparent;
    padding: 10px 10px;
    height: 50px;
    width: 150px;
    position: fixed;
    bottom: 0.10rem;
    right: 0.10rem;
    z-index: 100;
}

.callout-alert-small{
    display: flex;
    align-items: flex-start;
    background: transparent;
    padding: 5px 5px;
    height: 50px;
    width: 125px;
    position: fixed;
    bottom: 0.25rem;
    right: 0.10rem;
    z-index: 100;
}

.sticky {
    z-index: 5;
}

.button.whatsapp{
    color:white;
    background-color: #27c478;
}
.button.messenger{
    color:white;
}


.orbit-wrapper{
    //min-height: 56%;
    aspect-ratio: 1000/375 ;
    width: 100% ;
    //height: auto ; 

}


.orbit-wrapper-mobile{
    //min-height: 56%;
    aspect-ratio: 768/1024 ;
    width: 100% ;
    //height: auto ; 

}
.holiday{
    background: url("../../assets/img/beach.jpg");
    background-size:cover;
    background-repeat: no-repeat;
    z-index: 100;
        p {
               color:red;
               background-color: rgba(255,255,255,0.5 );
            }
}





