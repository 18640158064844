.marketing-site-features {
    text-align: center;
    padding: 1rem 0;

    .fa {
        font-size: 2rem;
        margin-bottom: 0.5rem;
        color: $primary-color;

    }

    .marketing-site-features-title {
        font-size: 1.25 rem;
        margin-bottom: 1rem;
        text-align: center;

    }

    @media screen and (min-width: 40em) {
        text-align: center;
    }
}

.marketing-site-features-headline {
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    font-variant: small-caps;
    color: $secondary-color;
}

.marketing-site-features-subheadline {
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 300;
    font-size: 1.5rem
}
